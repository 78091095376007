import React from 'react';
import Layout3 from '../components/layout4';


const HomePage = (props: any) => {

  return (
    <Layout3>
        <div style={{margin: "30px"}}>

          <h1>最新情報</h1>

<hr/>
          <h2>Ver0.9  - 3/xx</h2><br/>

          <h3>
            ジオグラフを試験公開しました。
          </h3>
          <p>
            <li>概要を<a href="/fsaas"><b>ジオグラフの使い方</b></a>からご確認ください。
            </li>
          </p>





{/* <img src="https://source.unsplash.com/featured/?mt.fuji" alt="" /> */}



      </div>
    </Layout3>
  );
};

export default HomePage;

